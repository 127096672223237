import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

//! INSTALL
//npm install crypto-js
//npm i --save-dev @types/crypto-js

@Injectable({
  providedIn: 'root',
})
export class EncryptService {
  private secretKey: string = environment.userKey;

  constructor() {}

  encrypt(token: string): string {
    return CryptoJS.AES.encrypt(token, this.secretKey).toString();
  }

  decrypt(token: string): string {
    const bytes = CryptoJS.AES.decrypt(token, this.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
