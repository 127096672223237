import { Injectable, inject } from '@angular/core';
import { EncryptService } from './encrypt.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private encryptService: EncryptService) {}

  save(name: string, value: any) {
    if (typeof value === 'object') {
      value = this.encryptService.encrypt(JSON.stringify(value));
    } else {
      value = this.encryptService.encrypt(String(value));
    }
    localStorage.setItem(name, value);
  }

  get(name: string) {
    const encryptedValue = localStorage.getItem(name);
    if (encryptedValue) {
      const decryptedValue = this.encryptService.decrypt(encryptedValue);
      try {
        return JSON.parse(decryptedValue);
      } catch (error) {
        return decryptedValue;
      }
    }
    return null;
  }

  clear() {
    localStorage.clear();
  }
}
